<template>
  <div class="not-file">
    <div>
      <el-image :src="notFileImg" />
      <div class="file-text">
        <h1>404</h1>
        <span>抱歉哈！您访问的页面不存在或仍在开发中</span>
        <el-button style="mini" type="primary" icon="el-icon-back" @click="go"
          >返回上一页</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { useRoute, useRouter } from 'vue-router'
export default {
  name: 'NotFile',
  setup() {
    const router = useRouter()
    const route = useRoute()
    const go = () => {
      const goPath = route.params.path || '/'
      router.push({ path: goPath })
    }
    const notFileImg = require('@/assets/image/404.9ed13bbe.svg')
    return { notFileImg, go }
  }
}
</script>

<style lang="scss" scoped>
.not-file {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  > div {
    display: flex;
    align-items: center;
    border-radius: 10px;
    padding: 15px;
    box-shadow: 0 0 5px 5px #eee;
    margin: auto;
    .el-image {
      width: 300px;
      height: 300px;
      padding: 15px;
    }
    .file-text {
      padding: 15px;
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      h1 {
        font: {
          size: 65px;
          weight: 700;
        }
        margin: 0;
        margin-bottom: 40px;
      }
      > span {
        font-size: 15px;
        margin-bottom: 25px;
      }
    }
  }
}
</style>
